// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgWarningTriangle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.616 3.641c1.057-1.838 3.71-1.838 4.768 0l8.043 13.988c1.054 1.833-.27 4.121-2.384 4.121H3.957c-2.115 0-3.438-2.288-2.384-4.12zm3.468.748a1.25 1.25 0 0 0-2.168 0L2.873 18.377a1.25 1.25 0 0 0 1.084 1.873h16.086a1.25 1.25 0 0 0 1.084-1.873zM12 8.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75m.568 9.25a.75.75 0 1 0-1.115-1.003l-.01.011a.75.75 0 1 0 1.114 1.004z"
    />
  </svg>
);
export const WarningTriangleIcon = forwardRef(SvgWarningTriangle);
